import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAtom } from "jotai";
import { Layout } from "antd";

import { authAtom } from "./atoms";
import Login from "./pages/login";
import Support from "./pages/support";
import searchUser from "./pages/getUserInfo";
import GetUserValidationInfo from "./pages/getValidations"
import GuardedRoute from "./components/GuardedRoute";
import Sidebar from "./components/sidebar";

export const routes = {
  login: "/login",
  support: "/support",
  searchUser: "/searchUser",
  searchValidationUser: "/validations"
};

function FinziRouter() {
  const [isAuth] = useAtom(authAtom.isAuth);
  const { Content } = Layout;
  return (
    <>
      <Router>
        <Suspense fallback={<Login />}>
          <div className="flex flex-nowrap overflow-hidden">

            <div className="overflow-hidden xl:w-[12%] border-r h-screen">
              <Sidebar />
            </div>

            <div className="w-full overflow-hidden h-auto">
              <Switch>
                <Route exact path={routes.login}>
                  <Login />
                </Route>
                <GuardedRoute
                  exact
                  path={routes.support}
                  component={Support}
                  auth={isAuth}
                />
                <GuardedRoute
                  exact
                  path={routes.searchUser}
                  component={searchUser}
                  auth={isAuth}
                />
                <GuardedRoute
                  exact
                  path={routes.searchValidationUser}
                  component={GetUserValidationInfo}
                  auth={isAuth}
                />

                <Route component={Login}>
                  <Login />
                </Route>
              </Switch>

            </div>

          </div>
          <Content
            className="site-layout-background"
          >

          </Content>

        </Suspense>
      </Router>
    </>
  );
}

export default FinziRouter;
