import axios from "axios";
import { APP_BACKEND } from "../../environments";

export const searchByEmail = async (email) =>
  await axios.get(`${APP_BACKEND}/support/userInformation?email=${email}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

export const searchByPhone = async (phone) =>
  await axios.get(
    `${APP_BACKEND}/support/userInformation?phone_number=${phone}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

export const searchByFinziCode = async (invitation_code) =>
  await axios.get(
    `${APP_BACKEND}/support/userInformation?invitation_code=${invitation_code}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

export const searchGameInfo = async (email) =>
  await axios.get(`${APP_BACKEND}/support/gameInformation?email=${email}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

export const searchMetamapInfo = async (email) =>
  await axios.get(`${APP_BACKEND}/support/matiInformation?email=${email}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

export const searchAccountInfo = async (userId) =>
  await axios.get(
    `${APP_BACKEND}/bankintegration/getAccountBalanceBySupport?userId=${userId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

export const searchMovementsInfo = async (userId) =>
  // /bankintegration/transactionsBySupport?userId=16972df0-209b-4d04-8223-20af9d6474f5&days=30
  await axios.get(
    `${APP_BACKEND}/bankintegration/transactionsBySupport?userId=${userId}&days=29`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

export const setSeverityNoneUser = async (userId) =>
  // Habilitar la apertura de cuenta
  await axios.patch(
    `${APP_BACKEND}/support/setSeverityNone?userId=${userId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

export const generateRandomPasswordUser = async (userId) =>
  // Habilitar la apertura de cuenta
  await axios.post(
    `${APP_BACKEND}/support/generateRandomPassword?userId=${userId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
