import React from "react";
import { Layout, Menu } from "antd";
import { useAtom } from "jotai";
import { useHistory, Link } from "react-router-dom";

import { authAtom } from "../../atoms";

import {
  UserOutlined,
  VideoCameraOutlined,
  LoginOutlined,
} from "@ant-design/icons";

function Sidebar() {
  const history = useHistory();
  const [isAuth, setIsAuth] = useAtom(authAtom.isAuth);
  const { Sider } = Layout;

  const logout = () => {
    setIsAuth(false);
    localStorage.setItem("token", "");
    localStorage.setItem("auth", false);
    history.push("/");
  };

  return (
    <>
      {isAuth && (     
        <Sider trigger={null} collapsible collapsed={false} width="100%">
          <div className="logo" />
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/searchUser">Buscar Usuario</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<VideoCameraOutlined />}>
              <Link to="/validations">Validaciones</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<LoginOutlined />} onClick={logout}>
              Cerrar Sesión
            </Menu.Item>
          </Menu>
        </Sider>
      )}
    </>
  );
}

export default Sidebar;
