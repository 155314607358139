import React, { useState } from "react";
import { Layout, Form, Input, Button, Select, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";

import "./index.css";
const { Header, Content } = Layout;

const Support = () => {
  const [form, form1] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [userData, setUserData] = useState([]);
  const [showUserVerification, setShowUserVerification] = useState(false);

  const { Option } = Select;
  const { confirm } = Modal;

  const DeactivateShowUserVerification = () => {
    setShowUserVerification(false);
  };

  const modifyUser = async (values) => {
    try {
      const data = {
        email: values.email,
        phone: values.phone,
        firstName: values.name,
        surname: values.surname,
        second_surname: values.second_surname,
        document_number: values.document_number,
        gender: values.gender,
        emission_date: values.documentExp.toString(),
        city: values.city,
      };
      const respose = await axios.put(
        "https://app.finzi.digital/support/updateUser/",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (respose.status === 200) {
        localStorage.setItem("userInfo", JSON.stringify(values));
      }
    } catch (error) {
      setError(error?.response?.statusText);
      setLoading(false);
      console.error(error);
    }
  };

  const createBankAccount = async () => {
    try {
      setLoading(true);
      const values = JSON.parse(localStorage.getItem("userInfo"));

      const data = {
        documentNumber: +values.document_number,
        documentExp: +values.documentExp,
        surname: values.surname,
        second_surname: values.second_surname,
        cell: +values.phone,
        email: values.email,
        firstname: values.name,
        city: values.city,
        gender: values.gender,
        documentType: values.doc_type,
      };
      await axios.post(
        "https://app.finzi.digital/bankintegration/createAccountSupport",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
    } catch (error) {
      setError(error?.response?.statusText);
      setLoading(false);
      console.error(error);
    }
  };

  const showConfirmCreateAccount = () => {
    confirm({
      title: "Estas Seguro que vamos a enviar estos campos?",
      icon: <ExclamationCircleOutlined />,
      content: "Estos cambios se enviaran al registro de cuenta",
      onOk() {
        createBankAccount();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showConfirmUpdateInfo = (values) => {
    confirm({
      title: "Modificar datos de usuarioo",
      icon: <ExclamationCircleOutlined />,
      content: "valida cambiosque los datos esten correctos",
      onOk() {
        modifyUser(values);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const searchUser = async (values) => {
    try {
      setError("");
      setLoading(true);
      setShowUserVerification(false);
      const data = {
        email: values.email,
      };
      const respose = await axios.post(
        "https://app.finzi.digital/support/userInfo",
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUserData(respose.data.data);
      setLoading(false);
      setShowUserVerification(true);
    } catch (error) {
      setError(error?.response?.statusText);
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Layout>
      <Header>Finzi</Header>
      <Content className="content-body">
        {!showUserVerification && (
          <Form form={form} layout="vertical" onFinish={searchUser}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Login
              </Button>
              {<label>{error}</label>}
            </Form.Item>
          </Form>
        )}
        {showUserVerification && (
          <Form
            form={form1}
            layout="vertical"
            initialValues={{
              city: userData?.city || "",
              doc_type: userData?.doc_type || "",
              document_number: userData?.document_number || "",
              email: userData?.email || "",
              gender: userData?.gender || "",
              name: userData?.name || "",
              phone: userData?.phone || "",
              second_surname: userData?.second_surname || "",
              surname: userData?.surname || "",
              documentExp: userData?.emission_date || "",
            }}
            onFinish={showConfirmUpdateInfo}
            size="small"
          >
            <Form.Item label="Nombre" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Primer apellido" name="surname">
              <Input />
            </Form.Item>
            <Form.Item label="Segundo apellido" name="second_surname">
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
            <Form.Item label="Ciudad de expedicion" name="city">
              <Input />
            </Form.Item>
            <Form.Item label="Tipo de documento" name="doc_type">
              <Input />
            </Form.Item>
            <Form.Item label="Documento" name="document_number">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="gender" label="Género">
              <Select placeholder="por favor seleccione genero">
                <Option value="M">Masculino</Option>
                <Option value="F">Femenino</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Télefono" name="phone">
              <Input />
            </Form.Item>
            <Form.Item label="fecha de expedición" name="documentExp">
              <Input placeholder="YYYYMMDD" />
            </Form.Item>

            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Validar datos
              </Button>
              {<label>{error}</label>}
            </Form.Item>
            {2 && (
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  onClick={showConfirmCreateAccount}
                >
                  Crear cuenta
                </Button>
                {<label>{error}</label>}
              </Form.Item>
            )}
            <Button
              type="dashed"
              size="small"
              onClick={DeactivateShowUserVerification}
            >
              Nueva Busqueda
            </Button>
          </Form>
        )}
      </Content>
    </Layout>
  );
};

export default Support;
