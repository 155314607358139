import React from "react";
import { Descriptions, Divider, Table } from "antd";

const columns = [
  {
    title: "Estado",
    dataIndex: "estado",
    key: "estado",
  },
  {
    title: "Monto",
    dataIndex: "monto",
    key: "monto",
    render: (text) => <spam>${normalizeCurrency(String(text))}</spam>,
  },
  {
    title: "Descripcion",
    dataIndex: "descripcion",
    key: "descripcion",
  },
  {
    title: "fechaTx",
    dataIndex: "fechaTx",
    key: "fechaTx",
    sorter: (a, b) => a.fechaTx - b.fechaTx,
  },
  {
    title: "tipo",
    dataIndex: "tipo",
    key: "tipo",
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
  },
];

const CARD_STATUS = {
  null: "No solicitada",
  0: "No solicitada",
  1: "Solicitada",
  2: "En proceso",
  3: "Creada",
  4: "Asociada",
  5: "Activada",
};

const normalizeCurrency = (value) => {
  let currency = value;
  while (currency.charAt(0) === "0") {
    currency = currency.substring(1);
  }
  return currency.slice(0, -2);
};

const UserBankAccountData = (user) => {
  const { userData, movementsData } = user;
  return (
    <>
      <Descriptions
        title="Información bancaria"
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Informacion bancaria">
          <strong>accountNumber: </strong>
          {userData.accountNumber}
          <br />
          <strong>accountStatus: </strong>
          {userData.accountStatus}
          <br />
          <strong>accountBalance: </strong>$
          {normalizeCurrency(userData.accountBalance) || "0"}
          <br />
          <strong>accumMonConsignments: </strong>$
          {normalizeCurrency(userData.accumMonConsignments) || "0"}
          <br />
          <strong>acumMonWithdrawal: </strong>$
          {normalizeCurrency(userData.acumMonWithdrawal) || "0"}
          <br />
          <strong>freezedAdmount: </strong>$
          {normalizeCurrency(userData.freezedAdmount) || "0"}
          <br />
          <strong>Status Tarjeta Fisica: </strong>
          {CARD_STATUS[userData.physucalCardStats]}
          <br />
          <strong>Status Tarjeta virtual: </strong>
          {CARD_STATUS[userData.virtualCardStatus]}
          <br />
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Movimientos</Divider>
      <Table columns={columns} dataSource={movementsData} />
    </>
  );
};

export default UserBankAccountData;
