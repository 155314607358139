import React from "react";
import { Descriptions } from "antd";

const UserGameData = (user) => {
  const { userData } = user;
  return (
    <Descriptions
      title="Información juegos"
      bordered
      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
    >
      <Descriptions.Item label="Scores">
        <strong>coins: </strong>
        {userData.score.coins}
        <br />
        <strong>last_badge: </strong>
        {userData.score.last_badge}
        <br />
        <strong>score: </strong>
        {userData.score.score}
        <br />
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UserGameData;
