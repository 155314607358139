import React from "react";
import { Descriptions } from "antd";

const UserMetamapData = (user) => {
  const { userData } = user;
  const documentInfo = JSON.parse(userData.profile.document_reading);
  const registraduria = JSON.parse(userData.profile.registraduria);
  const phoneInfo = JSON.parse(userData.profile.device_print);
  return (
    <Descriptions
      title="Metamap info"
      bordered
      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
    >
      <Descriptions.Item label="validaciones generales">
        <strong>created_at </strong>
        {userData.profile.created_at}
        <br />
        <strong>Intentos de validación: </strong>
        {userData.profile.attempt}
        <br />
        <strong>Cuantos errores en validación: </strong>
        {userData.profile.error_count}
        <br />
        <strong>mati_flow: </strong>
        {userData.profile.mati_flow}
        <br />
        <strong>validations_recomendtations: </strong>
        {userData.profile.validations_recomendtations}
        <br />
        <strong>mati_id: </strong>
        {userData.profile.mati_id}
      </Descriptions.Item>
      <Descriptions.Item label="Lectura de documento">
        <strong>Fecha de nacimiento </strong>
        {documentInfo.data?.dateOfBirth?.value}
        <br />
        <strong>documentNumber: </strong>
        {documentInfo.data?.documentNumber?.value}
        <br />
        <strong>fullName: </strong>
        {documentInfo.data?.fullName?.value}
        <br />
        <strong>Genero: </strong>
        {documentInfo.data?.sex?.value}
        <br />
      </Descriptions.Item>
      <Descriptions.Item label="registraduria">
        <strong>ageRange </strong>
        {registraduria?.data?.ageRange}
        <br />
        <strong>documentNumber </strong>
        {registraduria?.data?.documentNumber}
        <br />
        <strong>emissionDate </strong>
        {registraduria?.data?.emissionDate}
        <br />
        <strong>fullName </strong>
        {registraduria?.data?.fullName}
        <br />
        <strong>financialIndustryDebtsCount </strong>
        {registraduria?.data?.financialIndustryDebtsCount}
        <br />
        <strong>gender </strong>
        {registraduria?.data?.gender}
        <br />
        <strong>issuePlace </strong>
        {registraduria?.data?.issuePlace}
        <br />
        <strong>savingAccountsCount </strong>
        {registraduria?.data?.savingAccountsCount}
        <br />
        <strong>serviceIndustryDebtsCount </strong>
        {registraduria?.data?.serviceIndustryDebtsCount}
        <br />

      </Descriptions.Item>
      <Descriptions.Item label="Informacion de telefono">
        <strong>platform </strong>
        {phoneInfo.app?.platform}
        <br />
        <strong>version: </strong>
        {phoneInfo.app?.version}
        <br />
        <strong>ip: </strong>
        {phoneInfo.ip}
        <br />
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UserMetamapData;
