import axios from "axios";
import { APP_BACKEND } from "../../environments";

export const searchByEmail = async (email) =>
  await axios.get(`${APP_BACKEND}/support/matiInformation?email=${email}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
export const getUserInformation = async (email) =>
  await axios.get(`${APP_BACKEND}/support/userValidation?email=${email}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

export const searchByPhone = async (phone) =>
  await axios.get(
    `${APP_BACKEND}/support/matiInformation?searchBy=phone_number&data=${phone}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

export const searchByFinziCode = async (invitation_code) =>
  await axios.get(
    `${APP_BACKEND}/support/matiInformation?searchBy=invitation_code&data=${invitation_code}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

export const updateUser = async (email, information) =>
  await axios.patch(
    `${APP_BACKEND}/support/userValidation?email=${email}`,
    information,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
export const validateUser = async (email) =>
  await axios.patch(
    `${APP_BACKEND}/support/validateUser?email=${email}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
export const searchByEmailUser = async (email) =>
  await axios.get(`${APP_BACKEND}/support/userInformation?email=${email}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
