import React from "react";
import { Provider } from "jotai";
import { ToastContainer } from "react-toastify";
import FinziRouter from "./router";

import "react-toastify/dist/ReactToastify.css";

function App() {

  return (
    <Provider>
      <ToastContainer />
      <FinziRouter />
    </Provider>
  );
}

export default App;
