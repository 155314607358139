import React, { useState } from "react";
import { Divider, Layout, List, Modal } from "antd";
import { toast } from "react-toastify";
import { ExclamationCircleFilled } from "@ant-design/icons";
import UserDescription from "../../components/userdescription";
import UserGameData from "../../components/userGameData";
import UserMetamapData from "../../components/userMetamapData";
import UserBankAccountData from "../../components/UserBankAccountData";

import {
  searchByEmail,
  searchByPhone,
  searchByFinziCode,
  searchGameInfo,
  searchMetamapInfo,
  searchAccountInfo,
  searchMovementsInfo,
  setSeverityNoneUser,
  generateRandomPasswordUser,
} from "../../services/api/searchUsers";

import "./index.css";
import { Formik } from "formik";
const { Content } = Layout;
const { confirm } = Modal;
const GetUserInfo = () => {
  const [notifications, setNotifications] = useState([]);
  const [showUserVerification, setShowUserVerification] = useState(false);
  const [shoGameInfoModal, setShowGameInfoModal] = useState(false);
  const [showMetamapInfoModal, setShowMetamapInfoModal] = useState(false);
  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [matiInformation, setMatiInformation] = useState({});
  const [userGameData, setUserGameData] = useState([]);
  const [userMetamapData, setUserMetamapData] = useState([]);
  const [userBankAccountData, setUserBankAccountData] = useState([]);
  const [movementsData, setMovementsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [password, setPassword] = useState("");
  const showConfirm = () => {
    confirm({
      title: "Quiere generar una nueva contraseña?",
      icon: <ExclamationCircleFilled />,
      content: " ",
      onOk() {
        generateRandomPassword();
      },
      onCancel() {},
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const searchUser = async (values, setSubmitting) => {
    try {
      setShowUserVerification(false);
      const respose = await searchByEmail(values.email.toLowerCase().trim());
      const matiInformationResponse = await searchMetamapInfo(
        values.email.toLowerCase().trim()
      );
      const notificationsResponse = respose.data.data.notifications.map(
        (notification) => notification.text.message
      );
      setNotifications(notificationsResponse);
      setUserData(respose.data.data);
      setSubmitting(false);
      setShowUserVerification(true);
      setMatiInformation(matiInformationResponse.data.data.profile);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
      setSubmitting(false);
    }
  };

  const searchUserbyPhone = async (values, setSubmitting) => {
    try {
      setShowUserVerification(false);
      const respose = await searchByPhone(values.telefono);
      const notificationsResponse = respose.data.data.notifications.map(
        (notification) => notification.text.message
      );
      setNotifications(notificationsResponse);
      setUserData(respose.data.data);
      setSubmitting(false);
      setShowUserVerification(true);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
      setSubmitting(false);
    }
  };

  const getGameInfo = async () => {
    try {
      const respose = await searchGameInfo(
        userData.user.email.toLowerCase().trim()
      );
      setUserGameData(respose.data.data);
      setShowGameInfoModal(true);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
    }
  };

  const getAccountInfo = async () => {
    try {
      setLoading(true);
      const respose = await searchAccountInfo(userData.user.id);
      const movements = await searchMovementsInfo(userData.user.id);
      setUserBankAccountData(respose.data.data);
      setMovementsData(movements.data.data);
      setShowAccountInfoModal(true);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      console.error(error);
    }
  };
  const setSeverityNone = async () => {
    try {
      setLoading(true);
      await setSeverityNoneUser(userData.user.id);
      setLoading(false);
      setMatiInformation((matiInformation) => {
        return { ...matiInformation, severity: "none" };
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      console.error(error);
    }
  };
  const generateRandomPassword = async () => {
    try {
      setLoading(true);
      const response = await generateRandomPasswordUser(userData.user.id);
      setLoading(false);
      setPassword(response.data.message);
      showModal();
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      console.error(error);
    }
  };
  const getMetemapInfo = async () => {
    try {
      const respose = await searchMetamapInfo(
        userData.user.email.toLowerCase().trim()
      );
      setUserMetamapData(respose.data.data);
      setShowMetamapInfoModal(true);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
    }
  };

  const searchUserbyFinziCode = async (values, setSubmitting) => {
    try {
      setShowUserVerification(false);
      const respose = await searchByFinziCode(values.finziCode);
      const notificationsResponse = respose.data.data.notifications.map(
        (notification) => notification.text.message
      );
      setNotifications(notificationsResponse);
      setUserData(respose.data.data);
      setShowUserVerification(true);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="min-h-screen">
        <Content className="content-body">
          <p className="pb-10 text-xl font-bold">Buscar Usuario FinZi</p>
          {!showUserVerification && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <Formik
                  initialValues={{ email: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Ingrese el correo electrónico";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Ingresa un correo electrónico válido";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    searchUser(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <label className="block text-sm">
                          <span className="text-gray-700">
                            Buscar por correo electrónico
                          </span>
                          <input
                            name="email"
                            type="email"
                            onChange={handleChange}
                            value={values.email}
                            className="fz-input"
                            placeholder="ejemplo@finzi.co"
                          />
                          <p className="text-red-600">
                            {errors.email && touched.email && errors.email}
                          </p>
                          <button className="btn w-1/6" type="submit">
                            {isSubmitting ? (
                              <svg
                                role="status"
                                className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            ) : (
                              <>Buscar</>
                            )}
                          </button>
                        </label>
                      </form>
                    </>
                  )}
                </Formik>
                {/* Buscar por teléfono */}
                <Formik
                  initialValues={{ telefono: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.telefono) {
                      errors.telefono = "Ingrese el teléfono";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    searchUserbyPhone(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <label className="block text-sm">
                          <span className="text-gray-700">
                            Buscar por número de teléfono
                          </span>
                          <input
                            name="telefono"
                            type="number"
                            onChange={handleChange}
                            value={values.telefono}
                            className="fz-input"
                            placeholder="3030102313"
                          />
                          <p className="text-red-600">
                            {errors.telefono &&
                              touched.telefono &&
                              errors.telefono}
                          </p>
                          <button className="btn w-1/6" type="submit">
                            {isSubmitting ? (
                              <svg
                                role="status"
                                className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            ) : (
                              <>Buscar</>
                            )}
                          </button>
                        </label>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
              <Formik
                initialValues={{ finziCode: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.finziCode) {
                    errors.finziCode = "Ingrese el código FinZi";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  searchUserbyFinziCode(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <>
                    <form className="pt-6" onSubmit={handleSubmit}>
                      <label className="block text-sm">
                        <span className="text-gray-700">
                          Buscar por código FinZi
                        </span>
                        <input
                          name="finziCode"
                          type="number"
                          onChange={handleChange}
                          value={values.finziCode}
                          className="fz-input"
                          maxLength={5}
                          placeholder="302675"
                        />
                        <p className="text-red-600">
                          {errors.finziCode &&
                            touched.finziCode &&
                            errors.finziCode}
                        </p>
                        <button className="btn w-1/6" type="submit">
                          {isSubmitting ? (
                            <svg
                              role="status"
                              className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          ) : (
                            <>Buscar</>
                          )}
                        </button>
                      </label>
                    </form>
                  </>
                )}
              </Formik>
            </>
          )}
          {showUserVerification && userData && userData.length !== 0 && (
            <>
              <UserDescription userData={userData} />
              <Divider orientation="centre">ACCIONES</Divider>
              <div>
                <button
                  className={`btn w-1/4 mx-auto buttonSecundaryColor ${
                    !(
                      userData.user.account_verification_state === 2 &&
                      matiInformation.severity === "high"
                    ) && "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={
                    !(
                      userData.user.account_verification_state === 2 &&
                      matiInformation.severity === "high"
                    )
                  }
                  onClick={() => {
                    setSeverityNone();
                  }}
                >
                  {loading ? (
                    <svg
                      role="status"
                      className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    <> Habilitar Apertura de cuenta</>
                  )}
                </button>

                <button
                  className="btn w-1/4 mx-auto buttonSecundaryColor"
                  onClick={() => showConfirm()}
                  disabled={loading}
                >
                  {loading ? (
                    <svg
                      role="status"
                      className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    <> Generar contraseña aleatoria</>
                  )}
                </button>

                <button></button>
              </div>
              <Divider orientation="left">Notificaciones activas</Divider>

              <List
                bordered
                dataSource={notifications}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
              <div className="w-full flex">
                <button
                  className="btn w-1/4 mx-auto buttonSecundaryColor"
                  onClick={() => getGameInfo()}
                >
                  Información de juegos
                </button>
                {userData?.user.account_verification_state === 4 && (
                  <button
                    className="btn w-1/4 mx-auto buttonSecundaryColor"
                    onClick={() => getAccountInfo()}
                    disabled={loading}
                  >
                    {loading ? (
                      <svg
                        role="status"
                        className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      <> Balance de cuenta</>
                    )}
                  </button>
                )}
                {userData?.user.account_verification_state === 4 && (
                  <button
                    className="btn w-1/4 mx-auto buttonSecundaryColor"
                    onClick={() => getMetemapInfo()}
                  >
                    Metamap
                  </button>
                )}
              </div>
              <div className="w-full flex">
                <button
                  className="btn w-1/3 mx-auto "
                  onClick={() => setShowUserVerification(false)}
                >
                  Buscar nuevo usuario
                </button>
              </div>
            </>
          )}
        </Content>
        <Modal
          title="Game user information"
          centered
          visible={shoGameInfoModal}
          onOk={() => setShowGameInfoModal(false)}
          onCancel={() => setShowGameInfoModal(false)}
          width={1000}
        >
          <UserGameData userData={userGameData} />
        </Modal>
        <Modal
          title="Metamap user information"
          centered
          visible={showMetamapInfoModal}
          onOk={() => setShowMetamapInfoModal(false)}
          onCancel={() => setShowMetamapInfoModal(false)}
          width={1000}
        >
          <UserMetamapData userData={userMetamapData} />
        </Modal>
        <Modal
          title="Bank account information"
          centered
          visible={showAccountInfoModal}
          onOk={() => setShowAccountInfoModal(false)}
          onCancel={() => setShowAccountInfoModal(false)}
          width={1000}
        >
          <UserBankAccountData
            userData={userBankAccountData}
            movementsData={movementsData}
          />
        </Modal>
        <Modal
          title="Nueva contraseña"
          centered
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <button key="OK" onClick={handleOk}>
              OK
            </button>,
          ]}
        >
          <p>{password}</p>
        </Modal>
      </div>
    </>
  );
};

export default GetUserInfo;
