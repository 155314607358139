export const USER_STATUS = {
  0: "Pendiente verificar Correo",
  1: "Correo verificado (Puede jugar)",
  2: "Mati validado",
  3: "Pendiente Pin",
  4: "Cuenta bancaria creada",
};

export const ROLE = {
  0: "_Error_",
  1: "Joven",
  2: "Padre",
  3: "Soporte",
  4: "_Error_",
};

export const DEBIT_CARD_STATUS = {
  0: "No solicitada",
  1: "Solicitada",
  2: "En proceso",
  3: "Creada",
  4: "Asociada",
  5: "Activada",
};

export const calculateAge = (birthday) => {
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
