import { atom } from "jotai";

const initialAuth = JSON.parse(localStorage.getItem("auth")) || false;
const isAuth = atom(initialAuth);
const userData = atom({});

const atoms = {
  isAuth,
  userData,
};
export default atoms;
