import React, { useState } from "react";
import {
  Button,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
} from "antd";
import { toast } from "react-toastify";

import {
  searchByEmail,
  searchByPhone,
  searchByFinziCode,
  getUserInformation,
  updateUser,
  searchByEmailUser,
  validateUser,
} from "../../services/api/searchUserValidation";

import "./index.css";
const { Header, Content } = Layout;

const GetUserValidationInfo = () => {
  const [form, form1, form2] = Form.useForm();
  const [formValidations] = Form.useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showUserVerification, setShowUserVerification] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userInformation, setUserInformation] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [isAvailableToValidate, setIsAvailableToValidate] = useState(false);
  const [validateButton, setValidateButton] = useState(false);
  const [matiId, setMatiId] = useState("");
  const [matiResponse, setMatiResponse] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isUserUpdatedModal, setIsUserUpdatedModal] = useState(false);

  const searchUser = async (values) => {
    try {
      setError("");
      setLoading(true);
      setShowUserVerification(false);
      const respose = await searchByEmail(values.email);
      const user = (await searchByEmailUser(values.email)).data.data.user;
      const responseUserInformation = await getUserInformation(values.email);
      setUserEmail(values.email);
      setMatiId(respose.data.data.profile.mati_id);
      setUserInformation(responseUserInformation.data.data);
      if (
        user.account_verification_state === 20 ||
        user.account_verification_state === 21 ||
        user.account_verification_state === 22 ||
        (user.account_verification_state === 1 &&
          respose.data.data.profile.mati_id)
      ) {
        setIsAvailableToValidate(true);
      }
      setUserData(JSON.parse(respose.data.data.profile.response));

      setLoading(false);
      setShowUserVerification(true);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
      setError(error.response?.data?.message);
      setLoading(false);
    }
  };

  const searchUserbyPhone = async (values) => {
    try {
      setError("");
      setLoading(true);
      setShowUserVerification(false);
      const respose = await searchByPhone(values.phone);
      // const notificationsResponse = respose.data.data.notifications.map(
      //   (notification) => notification.text.message
      // );
      // setNotifications(notificationsResponse);
      setUserData(JSON.parse(respose.data.data.profile.response));
      setLoading(false);
      setShowUserVerification(true);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
      setError(error.response?.data?.message);
      setLoading(false);
    }
  };

  const searchUserbyFinziCode = async (values) => {
    try {
      setError("");
      setLoading(true);
      setShowUserVerification(false);
      const respose = await searchByFinziCode(values.finzi_code);
      // const notificationsResponse = respose.data.data.notifications.map(
      //   (notification) => notification.text.message
      // );
      // setNotifications(notificationsResponse);
      setUserData(JSON.parse(respose.data.data.profile.response));
      setLoading(false);
      setShowUserVerification(true);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
      setError(error.response?.data?.message);
      setLoading(false);
    }
  };
  const updateInformation = async (values) => {
    try {
      setError("");
      setLoading(true);
      values = { ...values, documentNumber: values.documentNumber.toString() };
      await updateUser(userEmail, values);
      setIsUserUpdatedModal(true);
      setLoading(false);
      setValidateButton(true);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(error);
      console.error(error.response?.data?.message);
      setError(error.response?.data?.message);
      setLoading(false);
    }
  };
  const validateUserMati = async () => {
    try {
      setError("");
      setLoading(true);
      const response = await validateUser(userEmail);
      setMatiResponse(response.data);
      setLoading(false);
      setIsModalOpen(true);
      if (response.data.statusCode === 201) {
        setIsAvailableToValidate(false);
        setValidateButton(false);
      }
    } catch (error) {
      console.error(error);
      console.error(error.response?.data?.message);
      setError(error.response?.data?.message);
      setLoading(false);
    }
  };
  const handleOk = () => {
    setIsUserUpdatedModal(false);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsUserUpdatedModal(false);
  };
  return (
    <>
      <Layout>
        <Header>Finzi</Header>
        <Content className="content-body">
          <Divider orientation="left">Verificaciones de mati</Divider>
          {!showUserVerification && (
            <>
              <Form form={form} layout="vertical" onFinish={searchUser}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <button className="btn w-1/6" type="submit">
                    {loading ? (
                      <svg
                        role="status"
                        className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      <>Buscar</>
                    )}
                  </button>
                  {<label>{error}</label>}
                </Form.Item>
              </Form>
              <Form form={form1} layout="vertical" onFinish={searchUserbyPhone}>
                <Form.Item
                  label="Telefono"
                  name="phone"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "Please input your phone!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item>
                  <button className="btn w-1/6" type="submit">
                    {loading ? (
                      <svg
                        role="status"
                        className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      <>Buscar</>
                    )}
                  </button>
                  {<label>{error}</label>}
                </Form.Item>
              </Form>
              <Form
                form={form2}
                layout="vertical"
                onFinish={searchUserbyFinziCode}
              >
                <Form.Item
                  label="finzi Code"
                  name="finzi_code"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "Please input your finzi code!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item>
                  <button className="btn w-1/6" type="submit">
                    {loading ? (
                      <svg
                        role="status"
                        className="inline mr-2 w-6 h-6 text-gray-300 animate-spin fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      <>Buscar</>
                    )}
                  </button>
                  {<label>{error}</label>}
                </Form.Item>
              </Form>
            </>
          )}

          {showUserVerification && userData && userData.length !== 0 && (
            <>
              <Form
                form={formValidations}
                layout="vertical"
                onFinish={updateInformation}
                initialValues={{
                  birthDate: userInformation.birthDate,
                  documentEmissionDate: userInformation.documentEmissionDate,
                  documentIssuePlace: userInformation.documentIssuePlace,
                  documentNumber: userInformation.documentNumber.toString(),
                  phoneNumber: userInformation.phoneNumber,
                }}
              >
                <Form.Item
                  label="número de documento"
                  name="documentNumber"
                  rules={[
                    {
                      type: "string",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="fecha de nacimiento"
                  name="birthDate"
                  rules={[
                    {
                      type: "date",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="fecha de expedición"
                  name="documentEmissionDate"
                  rules={[
                    {
                      type: "date",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="lugar de expedición"
                  name="documentIssuePlace"
                  rules={[
                    {
                      type: "string",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="número de teléfono"
                  name="phoneNumber"
                  rules={[
                    {
                      type: "string",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <strong>{`mati id: ${matiId}`}</strong>

                <button
                  className={`btn w-1/3 mx-auto  ${
                    !isAvailableToValidate && "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={!isAvailableToValidate}
                  type="primary"
                  htmlType="submit"
                >
                  Enviar
                </button>
              </Form>

              <div className="flex">
                <a
                  className="btn w-1/4 mx-auto buttonSecundaryColor"
                  href="https://www.dane.gov.co/files/censo2005/provincias/subregiones.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Municipios
                </a>
                <button
                  onClick={validateUserMati}
                  className={`btn w-1/4  mx-auto buttonSecundaryColor ${
                    !isAvailableToValidate &&
                    !validateButton &&
                    "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={!isAvailableToValidate && !validateButton}
                  type="primary"
                  htmlType="submit"
                >
                  Validar de nuevo
                </button>
              </div>

              <Descriptions
                title="Información de usuario"
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Datos de validacion">
                  <strong>Flow: </strong>
                  {userData.flow.name}
                  <br />
                  <strong>edad: </strong>
                  {userData.computed.age.data}
                  <br />
                  <strong>Celular: </strong>
                  {userData?.deviceFingerprint?.os?.name}
                  <br />
                </Descriptions.Item>
              </Descriptions>
              <Descriptions
                title="Validación"
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
              ></Descriptions>
              <Button
                loading={loading}
                type="secondary"
                onClick={() => setShowUserVerification(false)}
              >
                Buscar nuevo usuario
              </Button>
            </>
          )}
        </Content>
      </Layout>
      <Modal
        title="resultado validación"
        centered
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button key="OK" onClick={handleOk}>
            OK
          </button>,
        ]}
      >
        {isModalOpen &&
          Object.keys(matiResponse).map((item) => (
            <>
              {item === "message" ? (
                <ul key={item}>
                  {matiResponse[item].map((response) => {
                    return <p key={response}>{`*${response}\n`}</p>;
                  })}
                </ul>
              ) : (
                <p key={item}>{matiResponse[item]}</p>
              )}
            </>
          ))}
      </Modal>
      <Modal
        title="Actualizado"
        centered
        visible={isUserUpdatedModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button key="OK" onClick={handleOk}>
            OK
          </button>,
        ]}
      >
        <strong>usuario actualizado</strong>
      </Modal>
    </>
  );
};

export default GetUserValidationInfo;
