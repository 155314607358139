import React from "react";
import { USER_STATUS, ROLE, calculateAge, DEBIT_CARD_STATUS } from "./utils";
import { Descriptions } from "antd";

const UserDescription = (user) => {
  const { userData } = user;
  return (
    <Descriptions
      title="Información de usuario"
      bordered
      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
    >
      <Descriptions.Item label="Datos de usuario">
        <strong>Nombre: </strong>
        {userData.user.first_name}
        <br />
        <strong>Apellido: </strong>
        {userData.user.last_name}
        <br />
        <strong>Estado: </strong>
        {USER_STATUS[userData.user.account_verification_state]}
        <br />
        <strong>Fecha de creación: </strong>
        {userData.user.created_at}
        <br />
        <strong>Email: </strong>
        {userData.user.email}
        <br />
        <strong>Finzi Code: </strong>
        {userData.user.invitation_code}
        <br />
        <strong>Intentos fallidos de logeo: </strong>
        {userData.user.login_attempt}
        <br />
        <strong>Teléfono: </strong>
        {userData.user.phone_number}
        <br />
        <strong>Fecha de nacimiento: </strong>
        {userData.user.birth_date}
        <br />
        <strong>Edad: </strong>
        {calculateAge(new Date(userData.user.birth_date)) + " años"}
        <br />
        <strong>Genero: </strong>
        {userData.user.gender}
        <br />
        <strong>Rol: </strong>
        {ROLE[userData.user.role]}
        <br />
        <strong>Documento: </strong>
        {userData.user?.document_number}
        <br />
        <strong>Nickname: </strong>
        {userData.user?.nickname}
        <br />
        <strong>Version App instalada: </strong>
        {userData.user?.app_version}
      </Descriptions.Item>
      <Descriptions.Item label="Información adicional">
        <strong>Ultimo inicio de sesión: </strong>
        {new Date(userData.user.last_session).toDateString()}
        <br />
        {userData.father && (
          <>
            <strong>info del padre: </strong>
            {userData.father?.email}
            <br />
            {`${userData.father?.first_name} ${userData.father?.last_name}`}
          </>
        )}
        <br />
        <strong>Fecha creacion de cuenta: </strong>
        {new Date(userData.user.created_at).toDateString()}
        {userData?.sonsPayload?.map((s) => (
          <>
            <br />
            <strong>Info Hijos: </strong>
            <br />
            <strong>Nombre: </strong>
            {`${s.first_name} ${s.last_name}`}
            <br />
            <strong>Email: </strong>
            {s.email}
          </>
        ))}
      </Descriptions.Item>
      {userData.user.user_aditional_information && (
        <Descriptions.Item label="Información Bancaria">
          <strong>Ciudad: </strong>
          {userData.user.user_aditional_information.city}
          <br />
          <strong>Estado tarjeta física: </strong>
          {
            DEBIT_CARD_STATUS[
              userData.user.user_aditional_information
                .physical_debit_card_status
            ]
          }
          <br />
          <strong>Tipo tarjeta física: </strong>
          {userData.user.user_aditional_information.physical_debit_card_type}
          <br />
          <strong>Estado tarjeta virtual: </strong>
          {
            DEBIT_CARD_STATUS[
              userData.user.user_aditional_information.virtual_debit_card_status
            ]
          }
          <br />
          <strong>Tipo tarjeta virtual: </strong>
          {userData.user.user_aditional_information.virtual_debit_card_type}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default UserDescription;
